import * as React from "react";
import { graphql, navigate } from "gatsby";
import { IReservationConfirmationProps } from "../pageHelpers/ReservationConfirmation/ReservationConfirmationProps";
import {
  AllReservations,
  StyledContainer,
} from "../pageHelpers/ReservationConfirmation/ReservationConfirmationHelpers";
import Layout from "../components/global/Layout/Layout";
import { useAppSelector, useAppDispatch } from "../hooks";
import { useEffect, useState } from "react";
import { fetchHotelDetailsFromCore } from "../services/crs";
import {
  resConfirmationGTMDataLayer,
  addPageTypeInGTMDataLayer,
} from "../utils/datalayers";
import { setCheckout } from "../redux/slices/Checkout/checkout";
import { guestServiceProvider } from "../services/ServiceProvider";
import { setEditReservationCheckout } from "../redux/slices/Checkout/editReservationCheckout";
import { Col, Row } from "react-bootstrap";
import IHotel from "../@types/IHotel";
import ReservationCancelDialog from "../components/common/ReservationCancelDialog/ReservationCancelDialog";
import SEO from "../components/global/SEO/SEO";
import useMobileDetect from "../hooks/useMobileDetect";
import Title2 from "../components/global/Title2/Title2";

const ReservationConfirmation = (props: IReservationConfirmationProps) => {
  const _site = props.data.allRlhsite.edges[0].node;
  const dispatch = useAppDispatch();
  const location = props.location; //typeof window !== "undefined" ? window.location : null;
  const _reservations =
    location && location.state ? location.state.reservations : null;
  const [reservations, setReservations] = useState(
    _reservations ? _reservations : []
  );
  const [memberID, setMemberID] = useState();
  const [resListForGTM, setResListForGTM] = useState<Array<any>>([]);
  const [resCountForGTM, setResCountForGTM] = useState(0);
  const totalReservations = reservations ? reservations.length : 0;
  const [resToCancel, setResToCancel] = useState(null);
  const [brand, setBrand] = useState<any | null>(null);
  const [showComponent, setShowComponent] = useState(false);
  const confirmationType = location?.state
    ? location.state.confirmationType || "new"
    : "";
  const [pageTitle, setPageTitle] = useState(
    confirmationType == "new"
      ? "Reservation Confirmed!"
      : "Reservation Updated!"
  );
  const memberLoggedIn = useAppSelector(
    (state: any) => state.member.isLoggedIn
  );
  const isMobileOnly = useMobileDetect();
  const member = useAppSelector((state: { member: any }) => state.member);
  const _details = location && location.state && location.state.details;
  // const [phone, setPhone] = useState('');
  const [hotel, setHotel] = useState<IHotel | null>(null);

  const collapsedCards = { "0": { open: true } };

  const [reservationCancelDialogConfig, setReservationCancelDialogConfig] =
    useState({
      openDialog: false,
      res: null,
    });

  const onDialogClose = (cancelReq: any) => {
    setReservationCancelDialogConfig({ openDialog: false, res: null });
    if (cancelReq && cancelReq.status == "success") {
      const updatedReservations = reservations.map((res: any, i: number) => {
        if (
          res.crs_reservation_id === (resToCancel as any).crs_reservation_id
        ) {
          res.type = "cancelled";
          res.details.CancelID = cancelReq.cancelID;
        }
        setPageTitle("");
        return res;
      });

      setReservations(updatedReservations);
    }
  };

  useEffect(() => {
    if (reservations && reservations.length > 0) {
      setShowComponent(true);
    } else {
      navigate("/manage-reservation");
    }
  }, [reservations]);

  function getMonthName(monthNumber: string) {
    switch (monthNumber.replace(/^0+/, "")) {
      case "1":
        return "Jan";
      case "2":
        return "Feb";
      case "3":
        return "Mar";
      case "4":
        return "Apr";
      case "5":
        return "May";
      case "6":
        return "Jun";
      case "7":
        return "Jul";
      case "8":
        return "Aug";
      case "9":
        return "Sep";
      case "10":
        return "Oct";
      case "11":
        return "Nov";
      case "12":
        return "Dec";
      default:
        return "Invalid";
    }
  }

  const datesHeader = (arrival: string, departure: string) => {
    const arrivalStr = arrival.split("-");
    const departureStr = departure.split("-");

    const finalDateString =
      getMonthName(arrivalStr[1]) +
      " " +
      arrivalStr[2] +
      ", " +
      arrivalStr[0] +
      " - " +
      getMonthName(departureStr[1]) +
      " " +
      departureStr[2] +
      ", " +
      departureStr[0];

    return finalDateString;
  };

  const handleHotelDataDisplay = (hotel: any) => {
    setHotel(hotel);
  };

  const handleReservationDetailLoad = (resId: any, details: any) => {
    const resGTMs: Array<any> = resListForGTM ? resListForGTM : [];
    reservations.forEach((res: any, i: number) => {
      const isReservationPresent = resGTMs.some((GTMres) => {
        return GTMres.crs_reservation_id === res.crs_reservation_id;
      });
      if (res.crs_reservation_id === resId && !isReservationPresent) {
        if (res.details && res.details.RoomRates) {
          res.details.RoomRates = [...details.roomRates];
          res.details.RoomDescription = details.roomDescription;
        }
        // res.details = details;
        resGTMs.push(res);
      }
    });
    setResListForGTM(resGTMs);
    setResCountForGTM(resGTMs.length);
  };

  useEffect(() => {
    let didCancel = false;
    (async () => {
      if (hotel && !didCancel) {
        const data = await fetchHotelDetailsFromCore(
          (hotel as any).crs_code,
          null
        );
        if (data?.data?.length) {
          if (data.included) {
            const attr = data.included[0].attributes;
            const brandCode = attr?.brand_code;
            const brandName = attr?.name;
            !didCancel &&
              brandCode &&
              setBrand({
                code: brandCode,
                name: brandName,
              });
          }
        }
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [hotel]);

  useEffect(() => {
    if (
      resCountForGTM == reservations.length &&
      hotel &&
      confirmationType == "new" &&
      brand
    ) {
      try {
        resConfirmationGTMDataLayer(
          resListForGTM,
          hotel,
          brand,
          _details,
          confirmationType,
          false
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error setting gtm variables");
      }
    }
  }, [resCountForGTM, hotel, confirmationType, brand]);

  useEffect(() => {
    if (confirmationType == "new") {
      dispatch(setCheckout({}));
    } else {
      dispatch(setEditReservationCheckout({}));
    }
  }, []);

  useEffect(() => {
    addPageTypeInGTMDataLayer("reservation confirmation");
  }, []);

  useEffect(() => {
    const checkUser = async (email: string) => {
      const guestService = await guestServiceProvider();
      const response = await guestService.checkUserByEmail(email);
      if (response?.userByEmail?.userId) {
        setMemberID(response?.userByEmail?.userId);
      }
    };
    if (reservations && reservations?.[0]?.details?.guests?.[0]) {
      checkUser(reservations?.[0]?.details?.guests?.[0].email);
    }
  }, []);

  useEffect(() => {
    if (reservationCancelDialogConfig.openDialog) {
      setResToCancel(reservationCancelDialogConfig.res);
    }
  }, [reservationCancelDialogConfig]);

  return (
    showComponent && (
      <Layout {...{ site: _site }} showLogo>
        <SEO
          title={`Reservation Confirmation`}
          noIndex={true}
          noFollow={true}
        />
        <StyledContainer className="justify-content-center px-4" fluid="sm">
          {!isMobileOnly && memberLoggedIn && (
            <Row className="userinfodiv px-4">
              <Col className="p-0 mx-2">
                {member?.crmProfile.firstName} {member?.crmProfile.lastName}
              </Col>
              {member.isLoggedIn ? (
                <Col className="tier-div">
                  Member ID: {member.crmProfile.memberId}{" "}
                  <span className="tier">
                    {member.isLoggedIn
                      ? `${member?.crmProfile?.profileType}`
                      : null}{" "}
                  </span>
                </Col>
              ) : (
                <Col className="tier-div">
                  Member ID: {member.crmProfile.memberId}{" "}
                </Col>
              )}
            </Row>
          )}
          <Row className="px-4">
            <Col className="aside px-0">
              <Title2>{pageTitle}</Title2>
            </Col>
          </Row>
          {reservations?.[0]?.details && (
            <Row className="dates-header px-4">
              {datesHeader(
                reservations[0].details.Start,
                reservations[0].details.End
              )}{" "}
            </Row>
          )}
          <Row noGutters className="px-4">
            <Col className="px-0">
              <AllReservations
                reservations={reservations}
                collapsedCards={collapsedCards}
                setReservationCancelDialogConfig={
                  setReservationCancelDialogConfig
                }
                handleHotelDataDisplay={handleHotelDataDisplay}
                handleReservationDetailLoad={handleReservationDetailLoad}
                totalReservations={totalReservations}
              />
            </Col>
          </Row>
          <Row
            className="guest-detail-col-bottom mt-4 px-4"
            id="guest-details-bottomdiv"
          >
            {reservations?.[0]?.details?.guests?.[0] && (
              <>
                <Row className="labels pb-2">Guest Details </Row>
                <Row className="pb-2">
                  {reservations[0].details.guests[0].givenName}{" "}
                  {reservations[0].details.guests[0].surname}
                </Row>
                {memberID && (
                  <Col className="ps-0 pb-2">
                    Member ID: {memberID}{" "}
                    {member.isLoggedIn && (
                      <span className="tier">
                        {`${member?.crmProfile?.profileType} Tier`}{" "}
                      </span>
                    )}
                  </Col>
                )}
                <Row className="pb-2">
                  {reservations[0].details.guests[0].email}
                </Row>
              </>
            )}
          </Row>
          <ReservationCancelDialog
            config={reservationCancelDialogConfig}
            onHide={onDialogClose}
          />
        </StyledContainer>
      </Layout>
    )
  );
};
export const pageQuery = graphql`
  query ReservationConfirmationQuery {
    page: nodePage(path: { alias: { eq: "/reservation-confirmation" } }) {
      id
      title
      field_meta_description
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default ReservationConfirmation;
